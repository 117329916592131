import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { DataContext } from '../App';

// import IconTicket from '../assets/img/ic-ticket.svg';
// import IconDiscount from '../assets/img/ic-discount.svg';

import CouponRedCard from '../components/CouponRedCard';
import ButtonRound from '../components/ButtonRound';

let couponSelectedArr = []
let selectCouponData = [];
let selectCouponStatus = [];

function SelectCoupon(props) {
    const history = useHistory()
    const { groupType, dataApi, } = useContext(DataContext)

    const [dataCouponOverlap, setDataCouponOverlap] = useState([])

    const [error, setError] = useState('');
    // const [isLoaded, setIsLoaded] = useState(false);
    const [message, setMessage] = useState('');
    const [modalErr, setModalErr] = useState(false)

    const [confirm, setConfirm] = useState(false)
    const [windowH, setWindowH] = useState(window.innerHeight)

    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');

    // const [selectCouponStatus, setSelectCouponStatus] = useState([])

    const resizeWindow = () => {
        setWindowH(window.innerHeight)
    }


    const eventSelect = useCallback((val,idx) => {
        // let selectCouponStatusArr = [...selectCouponStatus]
        // if(!selectCouponStatus[idx]) {
        //     selectCouponStatusArr[idx] = true
        //     // setSelectCouponStatus(selectCouponStatusArr)
        //     selectCouponStatus = selectCouponStatusArr;
        // } else {
        //     selectCouponStatusArr[idx] = false
        //     // setSelectCouponStatus(selectCouponStatusArr)
        //     selectCouponStatus = selectCouponStatusArr;
        // }
        // console.log(selectCouponStatusArr)
        var i = couponSelectedArr.indexOf(val);
        if (i === -1) {
            couponSelectedArr.push(val); 
            selectCouponData = couponSelectedArr;
            document.getElementById(val).classList.add('_selected')
            document.getElementById('icon-check-'+val).classList.add('_selected')
        } else {
            couponSelectedArr.splice(i,1);
            selectCouponData = couponSelectedArr;
            document.getElementById(val).classList.remove('_selected')
            document.getElementById('icon-check-'+val).classList.remove('_selected')
        }
        // console.log(couponSelectedArr)
    },[])

    useEffect(() => {
        setError('')
        // setSelectCouponStatus([])
        selectCouponStatus = [];

        // Clear all value of coupon 
        couponSelectedArr = []
        selectCouponData = []
        console.log('start select page:',selectCouponData,'&',couponSelectedArr)

        window.addEventListener("resize", resizeWindow);

        // for(let i=0; i<dataApi.new_coupons.length; i++) {
            // eventSelect(dataApi.new_coupons[i].ecp_id,i)

            // Deselect all & check if only one coupon is select
            if(dataApi.new_coupons.length > 0) {
                eventSelect(dataApi.new_coupons[0].ecp_id,0)
            }
        // }

        return () => {
            resizeWindow()
            selectCouponStatus = [];

            // Clear all value of coupon 
            couponSelectedArr = []
            selectCouponData = []
            console.log('end select page:',selectCouponData,'&',couponSelectedArr)
        }
    }, [dataApi.new_coupons, eventSelect, windowH])

    const gotoHome = () => {
        let accessBy = localStorage.getItem('accessBy')
        console.log('go home: accessBy(',accessBy,')')
        if(accessBy === 'query') {
            // window.parent.location = localStorage.getItem('windowParentLocation');
            window.location.href='/?query='+localStorage.getItem('query')
        } else {
            window.location.href='/'
        }
    }
    
    const gotoSelectCouponConfirm = async () => {
        console.log(selectCouponData)
        // history.push('/selectcouponconfirm')
        const res = await fetch(`https://${apiLocation}/ecp/api/user/select_coupons`, {
            method: 'POST',
            body: JSON.stringify({
                "ecp_id": selectCouponData
            }),
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        res
        .json()
        .then(res => {
            if (res.success === 0) {
                    setModalErr(true);
                    // setMessage(res.message);
                    if(Object.keys(res.message).length !== 0) {
                        console.error(res)
                        if(typeof res.message === 'object') {
                            setMessage('ไม่สามารถทำรายการได้')
                        } else {
                            setMessage(res.message)
                        }
                    } else {
                        setMessage('ไม่สามารถทำรายการได้')
                    }
                    // setIsLoaded(true);

            } else {
                const data = res;
                console.log(data)
                // setIsLoaded(true);
                if (data.chk_overlap === undefined || data.chk_overlap.length === 0) {
                    history.push({
                        pathname: '/selectcouponconfirm',
                        state: {
                          data: selectCouponData,
                        },
                      })
                } else {
                    console.log('data.chk_overlap',data.chk_overlap)
                    setConfirm(true)
                    setDataCouponOverlap(data.chk_overlap)
                }
            }

        })
        .catch(err => {
            console.log(err);
            setModalErr(true);
            setMessage('ไม่สามารถทำรายการได้');
        });
    }

    function showEndDate(endDateVal) {
        const monthThArr = ['มกราคม','กุมภาพันธ์','มีนาคม','เมษายน','พฤษภาคม','มิถุนายน','กรกฎาคม','สิงหาคม','กันยายน','ตุลาคม','พฤศจิกายน','ธันวาคม'];
        const end = endDateVal
        
        if(!!end) {
            const endD = moment(end).format('DD');
            const endM = moment(end).format('M');
            const endY = moment(end).format('YYYY');
            const endTime = moment(end).format(' HH:mm')
    
            const valueEnddate = endD+' '+monthThArr[endM - 1]+' '+(Number(endY)+543)+' '+endTime;
            
            return valueEnddate;
        } else {
            return '- '
        }
    }

    

    const gotoSelectCouponConfirmPage = () => {
        history.push({
            pathname: '/selectcouponconfirm',
            state: {
              data: selectCouponData,
            },
          })
    }

    // const openModal = () => {
    //     setConfirm(true)
    // }
    const closeModal = () => {
        setConfirm(false)
    }
    
    const handleCloseModal = () => {
        setModalErr(false)
        // window.location.href="/"
        // history.push('/')
        let accessBy = localStorage.getItem('accessBy')
        console.log('go home: accessBy(',accessBy,')')
        if(accessBy === 'query') {
            // window.parent.location = localStorage.getItem('windowParentLocation');
            window.location.href='/?query='+localStorage.getItem('query')
        } else {
            window.location.href='/'
        }
    }

    Modal.setAppElement('#root');

    return (
        <React.Fragment>
            <div className="container-top">
                <h3>คูปองส่วนลด</h3>
            </div>

            {/* Status */}
            <div className="main-status">
                <div className="main-box box-style _flex">
                    <p className="main-notice">กดเลือกคูปองที่ต้องการใช้ <br/>หรือกดซ้ำเพื่อยกเลิกการเลือกคูปอง</p>
                </div>
            </div>

            {/* Tabs */}
            <Tabs>
                <TabList style={{display: 'none'}}>
                    <Tab>คูปองส่วนลด</Tab>
                </TabList>
                <TabPanel>
                    <div style={{height: windowH - 200+'px', paddingBottom: '60px', overflow: 'auto'}}>
                        {
                            dataApi.new_coupons.map((item,i)=> {
                                return (
                                    <CouponRedCard key={i} dataItem={item} dataGroupType={groupType} id={item.ecp_id} selected={selectCouponStatus[i]} onClick={()=>{eventSelect(item.ecp_id,i)}} />
                                )
                            })
                        }
                    </div>
                    <div className="btn-row _bottom" style={{ bottom: '0'}}>
                        <ButtonRound color="grey" text="ยกเลิก" onClick={gotoHome} />
                        <ButtonRound color="blue" text="ยืนยัน" onClick={gotoSelectCouponConfirm} />
                    </div>
                </TabPanel>
            </Tabs>
            <Modal 
                isOpen={confirm}
                className="_modal box-style modal-selectcoupon" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                    <div className="modal-content txt-center">
                        <h3>คูปองดังต่อไปนี้ได้ถูกเลือกในรหัสส่วนลดครั้งก่อนหน้านี้ไปแล้ว  </h3>
                        <ul className="modal-coupon-list">
                            {
                                dataCouponOverlap.map((item,i)=>{
                                    return (
                                        <li key={i}>
                                            <h3>{i+1}. คูปอง {!!(item.face_value) ? (item.face_value).toLocaleString('en-US') : 0} บาท</h3>
                                            <p>(หมดอายุ {showEndDate(item.end_date)})</p>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <h3>การสร้างรหัสส่วนลดใหม่<br/>จะยกเลิกรหัสส่วนลดก่อนหน้านี้</h3>
                    
                        <div className="btn-fluid txt-center">
                            <ButtonRound color="blue" text="สร้างส่วนลดใหม่" onClick={gotoSelectCouponConfirmPage} />
                            <ButtonRound color="grey" text="ไม่สร้างส่วนลดใหม่" onClick={closeModal} />
                        </div>

                    </div>
            </Modal>

            <Modal
                isOpen={modalErr}
                className="_modal box-style app"
                style={{
                overlay: {
                    backgroundColor: 'rgba(0,0,0,0.5)'
                }
                }
                }
            >
                <div className="_error-modal center">
                <p className="font-black">{message} {error}</p>
                {
                    message === 'คูปองนี้สิ้นสุดระยะเวลาในการเก็บแล้ว' || error === 'คูปองนี้สิ้นสุดระยะเวลาในการเก็บแล้ว' ? null : <p className="font-black">กรุณาลองอีกครั้ง</p>
                }
                <br />
                
                    <ButtonRound text="กลับหน้าหลัก" color="blue" onClick={handleCloseModal} />
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default SelectCoupon
