import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import Modal from 'react-modal';

import { /*Html5QrcodeScanner,*/ Html5Qrcode , /*Html5QrcodeSupportedFormats*/ } from 'html5-qrcode';

// import Modal from 'react-modal';

import { DataContext } from '../App';

import IconImg from '../assets/img/ic-image.svg';
import IconCamera from '../assets/img/ic-camera.svg';

import ButtonRound from '../components/ButtonRound';
import ButtonTransparent from '../components/ButtonTransparent';


const qrcodeRegionId = "html5qr-code-full-region";
let createConfig = {
    fps: 10,    // Optional frame per seconds for qr code scanning
    qrbox : 200,  // Optional if you want bounded box UI
    aspectRatio: 1.777778// 1.7777778
};

// let cameraList = []
let isAndroid = false
let isMobile = false
let isLoading = false
let uploadAction = false
let cameraActive = false
let cameraIdSelected;

let html5QrCode;

function ScanCouponV2() {
    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');

    const scanHeader = useRef(null)
    const { scanData, setScanData, /*groupType*/ } = useContext(DataContext === null ? '' : DataContext)
    const [scanDataDetail, setScanDataDetail] = useState([])
    const [scanDataList, setScanDataList] = useState([])
    const [qrcodeData, setQrcodeData] = useState('')
    const [btnAcceptActive, setBtnAcceptActive] = useState(false)
    const [btnActive, setBtnActive] = useState(false)
    const [cameraListActive, setCameraListActive] = useState(false)
    const [cameraList, setCameraList] = useState([])

    const [modalErrorRefreshIsOpen, setModalErrorRefreshIsOpen] = useState(false)
    const [modalInsertCodeIsOpen, setModalInsertCodeIsOpen] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false)
    const [errorMsg, setErrorMsg] = useState('เกิดข้อผิดพลาด')

    const handleScanQrcode = useCallback(async (qrcode, type) => {
        console.log('QR Code [',qrcode,'], QR Type[',type,']')
        // handle decoded results here
        if(!isLoading) {
            isLoading = true

            setTimeout(() => {
                (async()=>{
                    // history.push('/selectcouponconfirm')
                    const res = await fetch(`https://${apiLocation}/ecp/api/user/scan_qrcode`, {
                        method: 'POST',
                        body: JSON.stringify({
                            "qrcode": qrcode
                        }),
                        headers: {
                            "Authorization": "Bearer "+accessToken,
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        }
                    });
    
                    if(res.status === 200) {
                        res
                        .json()
                        .then(res => {
                            // console.log(res)
                            if (res.success === 0) {
                                setModalErrorIsOpen(true);
                                setErrorMsg(res.message? res.message : 'รหัส QR Code ไม่ถูกต้อง');
                                // setIsLoaded(true);
                
                            } else {
                                const data = res;
                                console.log(data)
                                console.log(JSON.stringify(data.ecp_list))
                                setScanData(data)
                                setScanDataDetail(data)
                                setScanDataList(data.ecp_list)
                                setConfirm(true)
                                setQrcodeData(qrcode)
                                // localStorage.setItem('scanData',JSON.stringify(data))
                                // window.parent.location = localStorage.getItem('windowParentLocation');
                                
                            }
                
                        })
                        .catch(err => {
                            console.log(err);
                            console.log(`Error scanning file. Reason: ${err}`)
                            setErrorMsg('QR Codeไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง')
                            setModalErrorIsOpen(true)
                            // setModalErrorUploadIsOpen(true)
                        });
                    } else if(res.status === 401) {
                        setErrorMsg('ไม่สามารถทำรายการได้ กรุณาลองใหม่อีกครั้ง')
                        setModalErrorRefreshIsOpen(true)
                        // setModalErrorUploadIsOpen(true)
                        if(cameraActive) {
                            await html5QrCode.stop()
                        }
                    } else {
                        res
                        .json()
                        .then(res => {
                            // console.log(res)
                            if (res.success === 0) {
                                console.log('Error[',res.status,']',res.message)
                                setModalErrorIsOpen(true);
                                setErrorMsg(res.message? res.message : 'รหัส QR Code ไม่ถูกต้อง');
                                // setIsLoaded(true);
                
                            }
                        })
                        
                    }
                })()
    
            }, 500);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[accessToken, apiLocation, setScanData])

    const qrCodeSuccessCallback = (qrcode) => {
        handleScanQrcode(qrcode, 'scan')
    };

    // Event input code -----------------------------------------------//
    const gotoInsertCode = () => {
        setScanData('')
        setBtnActive(false)
        setModalInsertCodeIsOpen(true)
    }

    const handleInputCode = (event) => {
        // console.log(event.target.value)
        !!event.target.value?setBtnActive(true): setBtnActive(false)
        
        setScanData(event.target.value)
    }

    // Event send insert code
    const sendCode = () => {
        isLoading = false
        setModalInsertCodeIsOpen(false)
        handleScanQrcode(scanData, 'text')
    } 

    const gotoHome = () => {
        window.parent.location = localStorage.getItem('windowParentLocation');
    }

    const closeModal = () => {
        isLoading = false
        setModalErrorIsOpen(false)
        setModalInsertCodeIsOpen(false)
        setConfirm(false)

        if(uploadAction) {
            document.getElementById('qr-input-file').value = null;
            // document.getElementById('qr-canvas-visible').remove();
            document.getElementById('qr-canvas').remove();
            uploadAction = false

            console.log('Input Clear!!')

            // const html5QrCode = new Html5Qrcode(qrcodeRegionId,
            //     { formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ] }
            // );

            // Check & selected camera after upload
            let cameraSelected = !!cameraIdSelected ? cameraIdSelected : { facingMode: "environment" }
            setTimeout(async () => {
                await html5QrCode.start(cameraSelected, createConfig, qrCodeSuccessCallback);
            },1250)
        };
    }
    
    Modal.setAppElement('#root');

    // Event Start (Check and setting camera)
    useEffect(() => {
        // const html5QrCode = new Html5Qrcode(qrcodeRegionId,
        //     { formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ] }
        // );

        (async()=> {
            // Check browser open on desktop or mobile
            const { userAgent } = navigator
            if((userAgent.includes('Android') || userAgent.includes('iPhone') || userAgent.includes('iPad')) && userAgent.includes('Mobile')) {
                isMobile = true
                if(userAgent.includes('Android')) {
                    isAndroid = true
                }
            }
            console.log(navigator)
            console.log(userAgent)
            // console.log(/Android|iPhone/i.test(navigator.userAgent))

            isLoading = true
            let deviceListHtml5QrcodeArr = []
            // Get camera devices
            await Html5Qrcode.getCameras().then(devices => {
                if (devices && devices.length) {
                    // devices.forEach((device, i) => {
                    //     console.log(`${i+1}. label = ${device.label}, id = ${device.id}`);
                    //     deviceListHtml5QrcodeArr.push({ id: device.id, label: device.label})
                    // });

                    for(let i=0; i<devices.length; i++) {
                        console.log(`${i+1}. label = ${devices[i].label}, id = ${devices[i].id}`)
                        deviceListHtml5QrcodeArr.push({ id: devices[i].id, label: devices[i].label})
                    }

                    setCameraList(deviceListHtml5QrcodeArr)
                }
                
                console.log('devices by Html5Qrcode.getCameras: ',devices)


                // If you want to prefer front camera
                setTimeout(async () => { // Delay for check the devices screen
                    // Declare script camera 
                    html5QrCode = new Html5Qrcode(qrcodeRegionId /*, { formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ] }*/);

                    // Start camera
                    await html5QrCode.start({ facingMode: "environment" }, createConfig, qrCodeSuccessCallback);
                    isLoading = false
                    cameraActive = true
                },1250)
        
            }).catch(err => {
                console.error(`${err.name}: ${err.message}`);
                setErrorMsg("ไม่สามารถเปิดกล้องได้ กรุณาลองใหม่อีกครั้ง");
                setModalErrorIsOpen(true);
                    
            });
    
        })()

    
        return () => {
            // Clear function start camera
            html5QrCode.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
                setErrorMsg("ไม่สามารถยกเลิกการเชื่อต่อกล้องได้");
                setModalErrorIsOpen(true);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [/*accessToken, apiLocation*/])

    // Event Upload
    useEffect(() => {
        // const html5QrCode = new Html5Qrcode(qrcodeRegionId,
        //     { formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ] }
        // );
        // File based scanning
        const fileinput = document.getElementById('qr-input-file');
        fileinput.addEventListener('change', e => {
            if (e.target.files.length === 0) {
                // No file selected, ignore 
                return;
            }

            uploadAction = true
            const imageFile = e.target.files[0];

            (async()=>{
                // Close cemera 
                if(cameraActive) {
                    await html5QrCode.stop()
                }

                // Scan QR Code
                await html5QrCode.scanFile(imageFile, true)
                    .then(decodedText => {
                        // success, use decodedText
                        isLoading = false
                        console.log('*** decodedText[UPLOAD] *** : ', decodedText);
                        handleScanQrcode(decodedText, 'upload')
                    })
                    .catch(err => {
                        // failure, handle it.
                        console.log(`Error scanning file. Reason: ${err}`)
                        setErrorMsg('QR Codeไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง')
                        setModalErrorIsOpen(true)
                    });
            })()
            
        });
        
        return () => {
            // Clear function start camera
            html5QrCode.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
                setErrorMsg("ไม่สามารถยกเลิกการเชื่อต่อกล้องได้");
                setModalErrorIsOpen(true);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const chooseCamera = async (cameraId, camreaLabel) => {
        setCameraListActive(false)

        let selectValue = cameraId
        console.log('CameraListSelect[id:',cameraId,'],[label:',camreaLabel)
        console.log('Camera Selected is Front [',camreaLabel.includes('front'),']')

        // if ( camreaLabel.includes('front') || camreaLabel.includes('Front') || camreaLabel.includes('FRONT') || camreaLabel.includes('หน้า') ) {
        //     selectValue = { facingMode: "user" }
        // }

        // const html5QrCode = new Html5Qrcode(qrcodeRegionId,
        //     { formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ] }
        // );
          
        // Close cemera 
        if(cameraActive) {
            await html5QrCode.stop()
        }
        // If you want to prefer camera
        setTimeout(async () => {
            await html5QrCode.start(selectValue, createConfig, qrCodeSuccessCallback);
        }, 1250);
        
        cameraIdSelected = cameraId
    }

    const handleAcceptCoupon = async () => {
        setBtnAcceptActive(true)
        const res = await fetch(`https://${apiLocation}/ecp/api/user/accept_coupons`, {
            method: 'POST',
            body: JSON.stringify({
                "qrcode": qrcodeData
            }),
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        res
        .json()
        .then(res => {
            if (res.success === 0) {
                setModalErrorIsOpen(true);
                setConfirm(false)
                if(Object.keys(res.message).length !== 0) {
                    console.error(res)
                    if(typeof res.message === 'object') {
                        setErrorMsg('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้')
                    } else {
                        setErrorMsg(res.message)
                    }
                } else {
                    setErrorMsg('ไม่สามารถทำรายการได้')
                }

            } else {
                const data = res;
                console.log(data)
                closeModal()

                // let accessBy = localStorage.getItem('accessBy')
                // if(accessBy === 'query') {
                //     const query = localStorage.getItem('query');
                //     window.parent.location = "/scancoupon/?query="+query;
                // } else {
                //     window.location.href='/'
                // }
                // window.parent.location = localStorage.getItem('windowParentLocation');
            }
            setBtnAcceptActive(false)

            isLoading = false
        })
        .catch(err => {
            console.log(err);
            setBtnAcceptActive(false)
            setModalErrorIsOpen(true);
            setErrorMsg('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้')

            isLoading = false
        });
    }
    
    const windowLocationReload = () => {
        setModalErrorRefreshIsOpen(false)
        window.location.reload()
    }

    return (
        <div className="scanqrcode-box" style={{height: window.innerHeight + 'px'}}>
            <div ref={scanHeader} className="_header bg-gradient-blue">
                <h3>สแกน QR</h3>
                <p>ให้ตำแหน่ง QR code อยู่ตรงกลางภาพ</p>
                <p style={{fontSize: '14px', lineHeight: '.8'}}>V.82</p>
            </div>

            <div className="_browsefile">
                {/* <input type='file' id='file'ref={inputFile} onChange={onIptChange} type="file" multiple accept="image/png,image/jpeg,image/bmp,image/gif" style={{display: 'none'}}/> */}
                {/* <button onClick={openImageDialog}>
                    <img src={IconImg} alt="icon" />
                </button> */}
                <label htmlFor="qr-input-file" className="custom-file-upload">
                    <img src={IconImg} alt="icon" />
                </label>
                <input type="file" id="qr-input-file" accept="image/*" style={{display: 'none'}}></input>
            </div>

            { isLoading && isMobile ? <svg className="dbrScanner-bg-loading" width="20px" height="20px" style={{ zIndex: '0'}} viewBox="0 0 1792 1792"><path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z"></path></svg> : null}

            {/* Camera Lists */}
            {
                cameraList.length > 1 ?
                <div className="camera-list">
                    <div className="btn-show-camera-list" onClick={()=> setCameraListActive(!cameraListActive)}>
                        <img src={IconCamera} alt="icon" />
                    </div>
                    { 
                        cameraListActive ? 
                            <ul className="btn-camera-list">{
                                cameraList.map((option, index) => (
                                    <li key={index} style={{marginBottom: '10px'}} className={(option.id === cameraIdSelected)?'active' : ''}>                                
                                        <ButtonRound color="blue" text={(option.label.includes('front') || option.label.includes('Front') || option.label.includes('FRONT') || option.label.includes('หน้า') ? 'กล้องหน้า ('+option.label+')' : (option.label.includes('back') || option.label.includes('Back') || option.label.includes('BACK') || option.label.includes('rear') || option.label.includes('Rear') || option.label.includes('REAR') || option.label.includes('หลัง')  ? 'กล้องหลัง ('+option.label+')' : option.label))} onClick={()=>{chooseCamera(option.id, option.label)}} />
                                    </li>
                                ))
                            }
                            </ul>
                        : null
                    }
                    
                </div>
                : null
            }

            {/* <div id={qrcodeRegionId} style={isMobile?{marginTop:'0'}:{marginTop:'79px'}} /> */}
            <div id={qrcodeRegionId} style={{marginTop: (isAndroid ? 0 : (scanHeader?.current?.offsetHeight) + 'px')}} />

            <div className="_footer">
                <ButtonTransparent text={'กรอกรหัสด้วยมือ'} onClick={gotoInsertCode} />
                <ButtonRound color="blue" style={{fontSize: '.8rem'}} text={'กดเพื่อดูคูปอง'} onClick={gotoHome} /> 
            </div>

            <Modal 
                isOpen={modalErrorIsOpen} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    {/* <p>รหัส QR Code {errorMsg}</p>
                    <p>กรุณาใช้ QR อันใหม่</p> */}
                    <p dangerouslySetInnerHTML={{__html: errorMsg}}></p>
                    <br/>
                    <ButtonRound text="ตกลง" color="blue" onClick={closeModal}/>
                </div>
            </Modal>

            <Modal 
                isOpen={modalErrorRefreshIsOpen} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    <p dangerouslySetInnerHTML={{__html: errorMsg}}></p>
                    <br/>
                    <ButtonRound text="ตกลง" color="blue" onClick={windowLocationReload}/>
                </div>
            </Modal>

            <Modal 
                isOpen={modalInsertCodeIsOpen} 
                className="_modal box-style modal-insertcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_insertcode-modal center">
                    <p>กรอกรหัส QR ในช่องด้านล่าง</p>
                    <input type="text" name="insertcode" value={scanData} onChange={handleInputCode} />
                    <br/>
                    <div className="btn-row">
                        <ButtonRound text="ยกเลิก" color="grey" onClick={closeModal}/>
                        {
                            btnActive ? 
                            <ButtonRound text="ถัดไป" color="blue" onClick={sendCode}/>
                            :
                            <ButtonRound text="ถัดไป" color="blue" style={{opacity: '0.5', pointerEvents: 'none'}} />
                        }
                        
                    </div>
                </div>
            </Modal>

            <Modal 
                isOpen={confirm}
                className="_modal box-style modal-selectcoupon" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="modal-content txt-center">
                    <h3>คุณต้องการให้ส่วนลด { !!(scanDataDetail.total_face_value) ? (scanDataDetail.total_face_value).toLocaleString('en-US') : 0 } บาท { !!scanDataDetail.total_minimum_purchase ? `(ยอดซื้อขั้นต่ำ ${scanDataDetail.total_minimum_purchase } แถว)` : null } <br/>ให้กับคุณ { scanDataDetail.gt_name } โดยแลกกับคูปอง</h3>
                    {/* {qrCodeData} */}
                    <ul className="modal-coupon-list">
                        {
                            scanDataList.map((item,i)=> {
                                return (
                                    <li key={i} style={{paddingBottom: '10px'}}>
                                        <h3>{ (i>0) ? i+1+'. ': '' }คูปอง {!!(item.face_value) ? (item.face_value).toLocaleString('en-US') : 0} + <span className="font-red">{!!(item.topup) ? (item.topup).toLocaleString('en-US') : 0} *</span> บาท</h3>
                                    </li>
                                )
                            })
                        }
                        
                    </ul>
                    <p className="font-red">* ท๊อปอัพเพิ่มเติมจาก PMI</p>
                    <br/>
                    {
                        btnAcceptActive ? 
                        <div className="btn-row txt-center" style={{'opacity': '.5', 'pointerEvents': 'none'}}>
                            <ButtonRound color="grey" text="ยกเลิก" style={{padding: '18px 1.2rem'}} />
                            <ButtonRound color="blue" text="ยืนยันและสแกนต่อ" style={{padding: '18px 1.2rem'}} />
                            {/* <ButtonRound color="blue" text="ยืนยัน" onClick={handleAcceptCoupon} /> */}
                        </div>
                        :
                        <div className="btn-row txt-center">
                            <ButtonRound color="grey" text="ยกเลิก" onClick={closeModal} style={{padding: '18px 1.2rem'}} />
                            <ButtonRound color="blue" text="ยืนยันและสแกนต่อ" onClick={handleAcceptCoupon} style={{padding: '18px 1.2rem'}} />
                            {/* <ButtonRound color="blue" text="ยืนยัน" onClick={handleAcceptCoupon} /> */}
                        </div>
                    }

                </div>
            </Modal>
        </div>
    )
}

export default ScanCouponV2
