import React, { useContext, useEffect, useCallback, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Carousel } from 'react-responsive-carousel';
import moment from 'moment'
import Modal from 'react-modal';

// import Mockup from '../Mockup.json'

import WsCard from '../components/WsCard'
import { DataContext } from '../App'
import ButtonRound from '../components/ButtonRound';

// import IconHistory from '../assets/img/ic-history.svg'
// import IconQrScan from '../assets/img/ic-qrcode.svg'
import IconCamera from '../assets/img/ic-camera-line.svg'
import WsWeekSummaryItem from '../components/WsWeekSummaryItem';
import WsShopSummaryItem from '../components/WsShopSummaryItem';
import WsPeddlerSummaryItem from '../components/WsPeddlerSummaryItem';


function HomeWs() {
    // moment.locale('th')
    const { dataApiWs, setDataApiWs } = useContext(DataContext)
    const apiLocation = localStorage.getItem('apiLocation')
    const accessToken = localStorage.getItem('accessToken');
    const amountAllWeek = 10

    const [cardLoading, setCardLoading] = useState(false)
    const [datePerWeek, setDatePerWeek] = useState('')
    const [cardActive, setCardActive] = useState(9)

    const [errorMsg] = useState('ไม่สามารถทำรายการได้')
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false)

    const fetchDataPerWeek = useCallback( async (indexShow) => {
        setCardLoading(true)
        const currentDay = moment().format('ddd')

        let numberCurrentWeek = moment().week()
        if (currentDay==='Sun') {
            numberCurrentWeek -= 1
        }

        let numberShowWeek =  numberCurrentWeek - indexShow
        
        let result = {
            "startDate": moment().day('Monday').week(numberShowWeek).format('YYYY-MM-DD'),
            "endDate": moment().day('Sunday').week(numberShowWeek+1).format('YYYY-MM-DD')
        }


        const res = await fetch(`https://${apiLocation}/ecp/api/user/status?startDate=${result.startDate}&endDate=${result.endDate}`, {
            method: 'GET',
            // body: JSON.stringify(result),
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        if(res.status === 200) {
            console.log(res)
            res.json().then((res)=> {
                setDataApiWs(res)

                setCardLoading(false)
            })
        } else {
            setModalErrorIsOpen(true)
        }

    },[accessToken, apiLocation, setDataApiWs])

    const getDatePerWeek = (indexShow) => {
        const currentDay = moment().format('ddd')
        const monthThArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'];

        let numberCurrentWeek = moment().week()
        if (currentDay==='Sun') {
            numberCurrentWeek -= 1
        }

        let numberShowWeek =  numberCurrentWeek - indexShow

        let startDate = moment().day('Monday').week(numberShowWeek).format('DD')
        let startMonth = monthThArr[ Number(moment().day('Monday').week(numberShowWeek).format('MM')) - 1 ]
        let startYear = Number(moment().day('Monday').week(numberShowWeek).format('YYYY')) + 543

        let endDate = moment().day('Sunday').week(numberShowWeek+1).format('DD')
        let endMonth = monthThArr[ Number(moment().day('Sunday').week(numberShowWeek+1).format('MM')) -1 ]
        let endYear = Number(moment().day('Sunday').week(numberShowWeek+1).format('YYYY')) + 543

        let showYear = (startYear!==endYear) ? ' ' + startYear + ' ' : ' - '
        let resultFull = startDate + ' ' + startMonth  + showYear + endDate + ' ' + endMonth + ' ' + endYear;
        // let resultLastCorrect = endDate + ' ' + endMonth + ' ' + endYear

        setDatePerWeek(resultFull)
        // setDateLastCorrect(resultLastCorrect)
    }


    // const getWsCard = useCallback( () => {
    //     let arrCardList = []
    //     for(let i=0; i<amountAllWeek; i++) {
    //         arrCardList.push( <div className=" px-1" key={i}><WsCard data={dataApiWs} loading={cardLoading} /></div>)
    //     }

    //     return arrCardList
    // },[cardLoading, dataApiWs])

    useEffect(() => {
        (async()=>{
            // 
            // getWsCard()
            getDatePerWeek(0)
        })()
        
        return () => {
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    

    const onChangeDataPerWeek = (ind) => {
        setCardActive(ind)
        let amountWeek = (amountAllWeek) - (ind + 1) // e.g. 10 - 
        if(ind !== cardActive) {
            fetchDataPerWeek(amountWeek)

        }
        getDatePerWeek(amountWeek)
    }

    const gotoScanCoupon = () => {
        let accessBy = localStorage.getItem('accessBy')
        const query = localStorage.getItem('query');
        // window.parent.location = "/scancoupon/?query="+query+'&openExternalBrowser=1';
        // window.parent.location = "/scancoupon/?query="+query;

        // Check LIFF in-app browser
        const { userAgent } = navigator
        // eslint-disable-next-line no-undef
        if(userAgent.includes('Line') && userAgent.includes('Mobile') && accessBy === 'id') {
            // *** Access via LINE (LineID) *** //
            // eslint-disable-next-line no-undef
            console.log('Check LIFF in-app browser: ',liff.isInClient(),', UserAgent includes Line: ',userAgent.includes('Line'))
            // eslint-disable-next-line no-undef
            // liff.openWindow({
            //     url: window.location.origin+"/scancoupon/?query="+query+"&openExternalBrowser=1",
            //     external: true,
            // });
            window.parent.location = "/scancoupon"

        } else if(userAgent.includes('Line') && userAgent.includes('Mobile') && accessBy === 'query') {
            // *** Access via LINE (Query) *** //
            // eslint-disable-next-line no-undef
            liff.openWindow({
                url: window.location.origin+"/scancoupon/?query="+query+"&openExternalBrowser=1",
                external: true,
            });
        } else {
            // *** Access via LINE on PC (Query) *** //
            window.parent.location = "/scancoupon/?query="+query+"&openExternalBrowser=1";
        }
    }

    const closeModal = () => {
        localStorage.setItem('scanData','')
        localStorage.removeItem('qrcodeID')
        localStorage.removeItem('eventID')
        localStorage.removeItem('name')
        setModalErrorIsOpen(false)
        // history.push('/')
        // window.location = '/';
        let accessBy = localStorage.getItem('accessBy')
        console.log('go home: accessBy(',accessBy,')')
        if(accessBy === 'query') {
            // window.parent.location = localStorage.getItem('windowParentLocation');
            window.location.href='/?query='+localStorage.getItem('query')
        } else {
            window.location.href='/'
        }
    }

    Modal.setAppElement('#root');

    return (
        <div className="ws-page" style={{maxWidth: window.innerWidth+'px'}}>
            <div className="head-section text-center px-1">
                <div className="flex content-between">
                    <div style={{width: '2rem', height: '2rem', margin: '0 10px'}}></div>
                    <div className="date-section">
                        <p className="text-1-8">{ datePerWeek }</p>
                        {/* Last Update */}
                        {/* <div className="flex m-auto text-center content-center items-center">
                            <p className="text-1">อัพเดทล่าสุด {dataApiWs.update_at} น.</p>
                            <img className="ic-history" src={IconHistory} alt="icon" />
                        </div> */}
                    </div>
                    <button onClick={()=> gotoScanCoupon()}>
                        <img src={IconCamera} alt="icon QR scan" style={{width: '2rem', height: '2rem'}} />
                    </button>
                </div>
            </div>
            <div className="card-section">
                {/* <Carousel emulateTouch={true} showArrows={false} showStatus={false} selectedItem={amountAllWeek-1} centerMode={true} centerSlidePercentage={92} onChange={onChangeDataPerWeek} onSwipeStart={()=> setCardLoading(true)}  onSwipeEnd={()=> setCardLoading(false)}> */}
                <Carousel 
                    emulateTouch={true} 
                    showArrows={false} 
                    showStatus={false} 
                    selectedItem={amountAllWeek-1} 
                    centerMode={true} 
                    centerSlidePercentage={92} 
                    onChange={onChangeDataPerWeek} 
                    onSwipeStart={()=> (cardActive === (amountAllWeek-1) || cardActive === 0) ? setCardLoading(false) : setCardLoading(true)} 
                    onSwipeEnd={()=> setCardLoading(false)} swipeScrollTolerance={0} preventMovementUntilSwipeScrollTolerance={false}
                >
                   {/* {
                    getWsCard()
                   } */}
                   <div id="0" className=" px-1"><WsCard data={dataApiWs} loading={cardLoading} /></div>
                   <div id="1" className=" px-1"><WsCard data={dataApiWs} loading={cardLoading} /></div>
                   <div id="2" className=" px-1"><WsCard data={dataApiWs} loading={cardLoading} /></div>
                   <div id="3" className=" px-1"><WsCard data={dataApiWs} loading={cardLoading} /></div>
                   <div id="4" className=" px-1"><WsCard data={dataApiWs} loading={cardLoading} /></div>
                   <div id="5" className=" px-1"><WsCard data={dataApiWs} loading={cardLoading} /></div>
                   <div id="6" className=" px-1"><WsCard data={dataApiWs} loading={cardLoading} /></div>
                   <div id="7" className=" px-1"><WsCard data={dataApiWs} loading={cardLoading} /></div>
                   <div id="8" className=" px-1"><WsCard data={dataApiWs} loading={cardLoading} /></div>
                   <div id="9" className=" px-1"><WsCard data={dataApiWs} loading={cardLoading} /></div>
                </Carousel>
            </div>
            {/* Tabs */}
            <Tabs className="tabs-ws px-1">
                <TabList>
                    <Tab>สรุปอาทิตย์</Tab>
                    <Tab>สรุปร้านค้า</Tab>
                    {
                        dataApiWs.peddler_list.length ?
                            <Tab>สรุปผู้ส่ง</Tab>
                        : null
                    }
                </TabList>
                
                {/* <div style={{height: window.innerHeight - 390+'px'}}> */}
                <div>
                    <TabPanel className="react-tabs__tab-panel">
                        {/* for Mockup */}
                        {/* <WsWeekSummaryItem data={Mockup} datePerWeek={datePerWeek} /> */}

                        <WsWeekSummaryItem data={dataApiWs} datePerWeek={datePerWeek} />
                    </TabPanel>
                    <TabPanel>
                        {/* for Mockup */}
                        {/* <WsShopSummaryItem data={Mockup} datePerWeek={datePerWeek} /> */}

                        <WsShopSummaryItem data={dataApiWs} datePerWeek={datePerWeek} />
                    </TabPanel>
                    {
                        dataApiWs.peddler_list.length ?
                        <TabPanel>
                            {/* for Mockup */}
                            {/* <WsPeddlerSummaryItem data={Mockup} datePerWeek={datePerWeek} /> */}

                            <WsPeddlerSummaryItem data={dataApiWs} datePerWeek={datePerWeek} />
                        </TabPanel>
                        : null
                    }
                    {/* <TabPanel><div className="underconstruction text-center my-2"><p>เมนู สรุปผู้ส่ง <br/>  <span>อยู่ในระหว่างดำเนินการ</span></p></div></TabPanel> */}
                </div>
            </Tabs>

            <Modal 
                isOpen={modalErrorIsOpen} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    <p>{errorMsg}</p>
                    <p className="font-black">กรุณาลองอีกครั้ง</p>
                    <br />
                    <br/>
                    <ButtonRound text="ตกลง" color="blue" onClick={closeModal}/>
                </div>
            </Modal>
        </div>
    )
}

export default HomeWs