import React, { useEffect, useState, useContext, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Modal from 'react-modal';
import moment from 'moment';

import { DataContext } from '../App';

import IconTicket from '../assets/img/ic-ticket.svg';
import IconDiscount from '../assets/img/ic-discount.svg';
import IconEmptyCoupon from '../assets/img/ic-emptycoupon.svg';

import CouponRedCardView from '../components/CouponRedCardView';
import Button3D from '../components/Button3D';
import ButtonRound from '../components/ButtonRound';
import CouponBlackCardView from '../components/CouponBlackCardView';
import CouponClickToCollectCard from '../components/CouponClickToCollectCard';
import HomeWs from './HomeWs';
import ScanCouponV2 from './ScanCouponV2';
// import ScanCoupon from './ScanCoupon';


function Home(props) {
    const ref = useRef()
    const history = useHistory()
    // const { dataCard } = props
    const { /*scanData,*/ groupType, dataApi } = useContext(DataContext)
    const [windowH, setWindowH] = useState(window.innerHeight)
    const scanData = localStorage.getItem('scanData')
    // const [redeemDataDetail, setRedeemDataDetail] = useState([])
    // const [redeemDataList, setRedeemDataList] = useState([])

    const resizeWindow = () => {
        setWindowH(window.innerHeight)
    }

    // const [qrCodeData, setQrCodeData] = useState(null)
    const [confirm, setConfirm] = useState(false)
    // const [dataNewCoupons, setDataNewCoupons] = useState([])
    const [clickToCollectData, setClickToCollectData] = useState({
        value: 0,
        date: null
    })
    const [clickToCollectDataPoint, setClickToCollectDataPoint] = useState({
        value: 0,
        date: null,
        point: 0,
        ecp_id: null,
        ecp_status: null,
    })
    const [clickToCollectDataAmount, setClickToCollectDataAmount] = useState(0)
    const [confirmClickToCollect, setConfirmClickToCollect] = useState(false)
    const [confirmClickToCollectPoint, setConfirmClickToCollectPoint] = useState(false)
    const [successClickToCollectPoint, setSuccessClickToCollectPoint] = useState(false)
    const [waitingClickToCollectPoint, setWaitingClickToCollectPoint] = useState(false)
    const [confirmRedeemView, setConfirmRedeemView] = useState(false)
    const [summaryRedeemAction, setSummaryRedeemAction] = useState(false)
    const [redeemDataDetail, setRedeemDataDetail] = useState([])
    const [redeemDataList, setRedeemDataList] = useState([])
    const [redeemActionDataDetail, setRedeemActionDataDetail] = useState([])
    const [redeemActionDataList, setRedeemActionDataList] = useState([])

    const [errorMsg, setErrorMsg] = useState('เกิดข้อผิดพลาด')
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false)
    const [modalErrorClickToCollectIsOpen, setModalErrorClickToCollectIsOpen] = useState(false)

    const [nameMsg, setNameMsg] = useState('')

    const [viewCouponData, setViewCouponData] = useState([]) 
    const [viewCouponDataList, setviewCouponDataList] = useState([]) 

    const query = localStorage.getItem('query');
    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');
    
    // const lineId = localStorage.getItem('lineId')
    const [accessBy] = useState(localStorage.getItem('accessBy'))

    // console.warn(dataApi.new_coupons)
    
// const mockup = [
//     {
//       "ecp_id": 99901,
//       "face_value": 60,
//       "topup": 10,
//       "ecp_status": "click-to-collect",
//       "use_date": null,
//       "ecp_value": 50,
//       "ecp_date": "2022-02-28 00:00:00",
//       "end_date": "2022-01-18 00:00:00",
//       "redeem_date": null
//     },
//     {
//       "ecp_id": 99902,
//       "face_value": 20,
//       "topup": 0,
//       "ecp_status": "click-to-collect",
//       "use_date": null,
//       "ecp_value": 20,
//       "ecp_date": "2022-02-28 00:00:00",
//       "end_date": "2022-01-19 00:00:00",
//       "redeem_date": null
//     },
    // {
    //   "ecp_id": 99903,
    //   "face_value": 20,
    //   "topup": 5,
    //   "ecp_status": "click-to-collect",
    //   "use_date": null,
    //   "ecp_value": 15,
    //   "ecp_date": "2022-02-28 00:00:00",
    //   "end_date": "2022-01-20 00:00:00",
    //   "redeem_date": null
    // },
    // {
    //   "ecp_id": 99904,
    //   "face_value": 50,
    //   "topup": 10,
    //   "ecp_status": "click-to-collect",
    //   "use_date": null,
    //   "ecp_value": 40,
    //   "ecp_date": "2022-02-28 00:00:00",
    //   "end_date": "2022-02-28 00:00:00",
    //   "redeem_date": null
    // },
    // {
    //   "ecp_id": 99905,
    //   "face_value": 100,
    //   "topup": 50,
    //   "ecp_status": "click-to-collect",
    //   "use_date": null,
    //   "ecp_value": 50,
    //   "ecp_date": "2022-02-28 00:00:00",
    //   "end_date": "2022-02-28 00:00:00",
    //   "redeem_date": null
    // },
    // {
    //   "ecp_id": 99906,
    //   "face_value": 250,
    //   "topup": 0,
    //   "ecp_status": "click-to-collect",
    //   "use_date": null,
    //   "ecp_value": 250,
    //   "ecp_date": "2022-02-28 00:00:00",
    //   "end_date": "2022-03-18 00:00:00",
    //   "redeem_date": null
    // }
//   ]



    function showEndDate(endDateVal) {
        const monthThArr = ['มกราคม','กุมภาพันธ์','มีนาคม','เมษายน','พฤษภาคม','มิถุนายน','กรกฎาคม','สิงหาคม','กันยายน','ตุลาคม','พฤศจิกายน','ธันวาคม'];
        const end = endDateVal
        
        const endD = moment(end).format('DD');
        const endM = moment(end).format('M');
        const endY = moment(end).format('YYYY');
        // const endTime = moment(end).format(' HH:mm')

        const valueEnddate = endD+' '+monthThArr[endM - 1]+' '+(Number(endY)+543); //+' '+endTime;
        
        return valueEnddate;
    }

    const getViewCouponData =  useCallback(async (qrid) => {
        console.log('apiLocation',apiLocation)
        console.log('accessToken',accessToken)
        console.log('qr_id',qrid)
        
            const res = await fetch(`https://${apiLocation}/ecp/api/user/view_coupons`, {
                    method: 'POST',
                    body: JSON.stringify({
                        "qr_id": qrid
                    }),
                    headers: {
                        "Authorization": "Bearer "+ accessToken,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    }
                });

            res
            .json()
            .then(res => {
                if (res.success === 0) {
                    setModalErrorIsOpen(true);
                    // setErrorMsg('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้');
                    // setIsLoaded(true);
                    if(Object.keys(res.message).length !== 0) {
                        console.error(res)
                        if(typeof res.message === 'object') {
                            setErrorMsg('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้')
                        } else {
                            setErrorMsg(res.message)
                        }
                    } else {
                        setErrorMsg('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้')
                    }

                } else {
                    const data = res;
                    console.log('viewCouponData',data)
                    console.log('viewCouponData > ecp_list',data.ecp_list)
                    setViewCouponData(data);
                    setviewCouponDataList(data.ecp_list)
                    setConfirm(true)
                    // window.parent.location = localStorage.getItem('windowParentLocation');
                }

            })
            .catch(err => {
                console.log(err);
            });
        

        
    },[accessToken, apiLocation])

    const getViewRedeemData =  useCallback(async (eventid) => {
        console.log('apiLocation',apiLocation)
        console.log('accessToken',accessToken)
        console.log('event_id',eventid)
        
            const res = await fetch(`https://${apiLocation}/ecp/api/user/view_redeem`, {
                    method: 'POST',
                    body: JSON.stringify({
                        "event_id": eventid
                    }),
                    headers: {
                        "Authorization": "Bearer "+ accessToken,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    }
                });

            res
            .json()
            .then(res => {
                if (res.success === 0) {
                    setModalErrorIsOpen(true);
                    setErrorMsg(res.message);
                    // setIsLoaded(true);

                    if(Object.keys(res.message).length !== 0) {
                        console.error(res)
                        if(typeof res.message === 'object') {
                            setErrorMsg('ไม่สามารถทำรายการได้')
                        } else {
                            setErrorMsg(res.message)
                        }
                    } else {
                        setErrorMsg('ไม่สามารถทำรายการได้')
                    }

                } else {
                    const data = res;
                    console.log('redeemData',data)
                    console.log('redeemData > ecp_list',data.ecp_list)
                    setRedeemDataDetail(data);
                    setRedeemDataList(data.ecp_list)
                    setConfirmRedeemView(true)
                    // window.parent.location = localStorage.getItem('windowParentLocation');
                }

            })
            .catch(err => {
                console.log(err);
            });
    },[accessToken, apiLocation])

    const handleActionRedeem =  useCallback(async (action) => {
        console.log('apiLocation',apiLocation)
        console.log('accessToken',accessToken)
        console.log('event_action',action)
        
            const res = await fetch(`https://${apiLocation}/ecp/api/user/action_redeem`, {
                    method: 'POST',
                    body: JSON.stringify({
                        "event_id": localStorage.getItem('eventID'),
                        "action": action
                    }),
                    headers: {
                        "Authorization": "Bearer "+ accessToken,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    }
                });

            res
            .json()
            .then(res => {
                if (res.success === 0) {
                    setModalErrorIsOpen(true);
                    if(Object.keys(res.message).length !== 0) {
                        console.error(res)
                        if(typeof res.message === 'object') {
                            setErrorMsg('ไม่สามารถทำรายการได้')
                        } else {
                            setErrorMsg(res.message)
                        }
                    } else {
                        setErrorMsg('ไม่สามารถทำรายการได้')
                    }
                    // setIsLoaded(true);

                } else {
                    const data = res;
                    console.log('redeemDataAction',data)
                    console.log('redeemDataAction > ecp_list',data.ecp_list)
                    // setRedeemDataDetail(data);
                    // setRedeemDataList(data.ecp_list)
                    setConfirmRedeemView(false)
                    if(action==='accept') {
                        setSummaryRedeemAction(true)
                        setRedeemActionDataDetail(data);
                        setRedeemActionDataList(data.ecp_list)

                    } else {

                        localStorage.removeItem('qrcodeID')
                        localStorage.removeItem('eventID')
                        // window.location = '/';
                        let accessBy = localStorage.getItem('accessBy')
                        console.log('go home: accessBy(',accessBy,')')
                        if(accessBy === 'query') {
                            window.parent.location = localStorage.getItem('windowParentLocation');
                        } else {
                            window.location.href='/'
                        }
                    }
                    // window.parent.location = localStorage.getItem('windowParentLocation');
                }

            })
            .catch(err => {
                console.log(err);
            });
    },[accessToken, apiLocation])


    const handleConfirmClickToCollect = useCallback( async(item)=>{
        // console.log(item)
        if(item.ecp_status !== "uncollected") {
            setConfirmClickToCollectPoint(false)
            setWaitingClickToCollectPoint(true)

            // hide select coupon
            let self = document.getElementById(item.ecp_id)
            self.style.display = "none"
        }

        // Click-to-collect
        const resConfirm = await fetch(`https://${apiLocation}/ecp/api/user/collect_coupons`, {
                method: 'POST',
                body: JSON.stringify({
                    "ecp_id": [item.ecp_id]
                }),
                headers: {
                    "Authorization": "Bearer "+ accessToken,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });

        if(resConfirm.status === 200) {
            resConfirm
            .json()
            .then(res => {
                if (res.success === 0) {
                    setWaitingClickToCollectPoint(false)
                    setModalErrorClickToCollectIsOpen(true);
                    // setErrorMsg('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้');
                    // setIsLoaded(true);
                    if(Object.keys(res.message).length !== 0) {
                        console.error(res)
                        if(typeof res.message === 'object') {
                            setErrorMsg('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้')
                        } else {
                            setErrorMsg(res.message)
                        }
                    } else {
                        setErrorMsg('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้')
                    }
    
                } else {
                    const data = res;
                    console.log('pick_couponss',data)
                    // console.log('item:ecp_status:',item.ecp_status)
    
                    if(item.ecp_status === "uncollected") {
                        handleCloseClickToCollect()
                        setClickToCollectData({
                            value: item.ecp_value,
                            date: !!item.ecp_date ? showEndDate(item.ecp_date) : null
                        })
                        console.log('Click-to-collect Success...')
                    } else {
                        console.log('select id:',item.ecp_id)
                        console.log('GraphQL waiting...')
                    }
    
                    // window.parent.location = localStorage.getItem('windowParentLocation');
                }
    
            })
            .catch(err => {
                setWaitingClickToCollectPoint(false)
                console.log(err);
                setModalErrorIsOpen(true);
                setErrorMsg('ไม่สามารถทำรายการได้');
            });
        } else {
            console.error('Error Code: ',resConfirm.status)
            setModalErrorIsOpen(true);
            setErrorMsg('ไม่สามารถทำรายการได้');
        }

    },[accessToken,apiLocation])

    const handleSelectClickToCollect = (item) => {
        // Click-to-collect 
        if(item.ecp_status === 'uncollected') {
            console.log('Click-to-collect')
            handleConfirmClickToCollect(item) 
        } else {
        // Click-to-collect : point
            console.log('Click-to-collect : Point')
            setConfirmClickToCollectPoint(true)
            setClickToCollectDataPoint({
                value: item.ecp_value,
                date: !!item.ecp_date ? showEndDate(item.ecp_date) : null,
                point: item.sf_point,
                ecp_id: item.ecp_id,
                ecp_status: item.ecp_status,
            })
        }
    }


    // const handlePickClickToCollect =  useCallback(async (item) => {
    //     console.log('apiLocation',apiLocation)
    //     console.log('accessToken',accessToken)
    //     console.log('ecp_id',item.ecp_id)
        
    //     const resPick = await fetch(`https://${apiLocation}/ecp/api/user/pick_coupons`, {
    //             method: 'POST',
    //             body: JSON.stringify({
    //                 "ecp_id": [item.ecp_id]
    //             }),
    //             headers: {
    //                 "Authorization": "Bearer "+ accessToken,
    //                 "Content-Type": "application/json",
    //                 "Accept": "application/json"
    //             }
    //         });

    //     resPick
    //     .json()
    //     .then(res => {
    //         if (res.success === 0) {
    //             setModalErrorIsOpen(true);
    //             // setErrorMsg('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้');
    //             // setIsLoaded(true);
    //             if(Object.keys(res.message).length !== 0) {
    //                 console.error(res)
    //                 if(typeof res.message === 'object') {
    //                     setErrorMsg('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้')
    //                 } else {
    //                     setErrorMsg(res.message)
    //                 }
    //             } else {
    //                 setErrorMsg('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้')
    //             }

    //         } else {
    //             const data = res;
    //             console.log('pick_couponss',data)
    //             handleConfirmClickToCollect(item)
    //             // window.parent.location = localStorage.getItem('windowParentLocation');
    //         }

    //     })
    //     .catch(err => {
    //         console.log(err);
    //     });
        
    // },[accessToken, apiLocation, handleConfirmClickToCollect])

    const handleCloseClickToCollect = () => {
        setConfirmClickToCollect(true)
        
    }

    // const handleSuccessClickToCollectPoint = () => {
    //     setConfirmClickToCollectPoint(false)
    //     setSuccessClickToCollectPoint(true)
    // }

    useEffect(() => {
        // Reset CameraId Selected
        localStorage.removeItem('cameraIdSelected')

        setClickToCollectDataAmount(dataApi.collect_coupons.length)
        let qrID = localStorage.getItem('qrcodeID')
        let eventID = localStorage.getItem('eventID')

        console.log('qr_id:',qrID)
        console.log('event_id:',eventID)

        if(qrID==='collect_coupons') {
            console.log('hi click-to-collect')
            setWaitingClickToCollectPoint(false)
            setSuccessClickToCollectPoint(true)
            setNameMsg(localStorage.getItem('name'))
        } else {
            console.log('hi, version: 2.0.2')
            if(localStorage.getItem('qrcodeID')!=='' && !!localStorage.getItem('qrcodeID')) {
                getViewCouponData(localStorage.getItem('qrcodeID'))
                console.log('GT Summary: qr_id',localStorage.getItem('qrcodeID'))  
                
            } else {
                console.log('GT Summary: no data',localStorage.getItem('qrcodeID')) 
            }
            
            if(localStorage.getItem('eventID')!=='' && !!localStorage.getItem('eventID')) {
                getViewRedeemData(localStorage.getItem('eventID'))
                console.log('WS/GT Summary: event_id',localStorage.getItem('eventID'))
                
            } else {
                console.log('WS/GT Summary: no data',localStorage.getItem('eventID'))
            }
        }

        // setQrCodeData(scanData)
        window.addEventListener("resize", resizeWindow);
        // console.log(dataApi.new_coupons.length)
        // console.log(dataNewCoupons)
        // setDataNewCoupons(dataApi.new_coupons)
        // if(!!scanData) {
        //     // console.log('qrCodeData 1', JSON.parse(scanData))
        //     // setRedeemDataDetail(JSON.parse(scanData))
        //     // setRedeemDataList(JSON.parse(scanData).ecp_list)
        //     setConfirm(true)
        // } else {
        //     // console.log('qrCodeData 2', qrCodeData)
        //     setConfirm(false)
        // }

        return () => {
            resizeWindow()
        }

    }, [dataApi, getViewCouponData, getViewRedeemData, scanData, windowH])

    const gotoSelectCoupon = () => {
        history.push('/selectcoupon')
    }
    const gotoScanCoupon = () => {
        // history.push('/scancoupon')
        // Check LIFF in-app browser
        const { userAgent } = navigator
        // eslint-disable-next-line no-undef
        if(!liff.isInClient() && userAgent.includes('Line')) {
            // eslint-disable-next-line no-undef
            console.log('Check LIFF in-app browser: ',liff.isInClient(),', UserAgent includes Line: ',userAgent.includes('Line'))
            // eslint-disable-next-line no-undef
            liff.openWindow({
                url: window.location.origin+"/scancoupon/?query="+query+"&openExternalBrowser=1",
                external: true,
            });
        } else {
            window.parent.location = "/scancoupon/?query="+query;
        }

    }

    const gotoScanCouponViaLIFF = () => {
        // eslint-disable-next-line no-undef
        // liff.openWindow({
        //     url: window.location.origin+"/scancoupon/?openExternalBrowser=1",
        //     external: true,
        // });

        // Check LIFF in-app browser
        const { userAgent } = navigator
        // eslint-disable-next-line no-undef
        if((userAgent.includes('Line') && userAgent.includes('Mobile') && accessBy === 'id') || accessBy === 'id' ) {
            // *** Access via LINE (LineID) *** //
            // eslint-disable-next-line no-undef
            console.log('Check LIFF in-app browser: ',liff.isInClient(),', UserAgent includes Line: ',userAgent.includes('Line'))
            // eslint-disable-next-line no-undef
            // liff.openWindow({
            //     url: window.location.origin+"/scancoupon/?query="+query+"&openExternalBrowser=1",
            //     external: true,
            // });
            // window.parent.location = "/scancoupon"
            return (<ScanCouponV2/>)

        } else if(userAgent.includes('Line') && userAgent.includes('Mobile') && accessBy === 'query') {
            // *** Access via LINE (Query) *** //
            // eslint-disable-next-line no-undef
            liff.openWindow({
                url: window.location.origin+"/scancoupon/?query="+query+"&openExternalBrowser=1",
                external: true,
            });
        } else {
            // *** Access via LINE on PC (Query) *** //
            window.parent.location = "/scancoupon/?query="+query+"&openExternalBrowser=1";
        }
    }

    // Close modal

    const closeModalConfirmClickToCollect = () => {
        setConfirmClickToCollect(false)
        // window.location = '/';
        let accessBy = localStorage.getItem('accessBy')
        console.log('go home: accessBy(',accessBy,')')
        if(accessBy === 'query') {
            // window.parent.location = localStorage.getItem('windowParentLocation');
            window.location.href='/?query='+localStorage.getItem('query')
        } else {
            window.location.href='/'
        }
    }

    const closeModalConfirm = () => {
        localStorage.removeItem('qrcodeID')
        setConfirm(false)
        setModalErrorIsOpen(false);
        // window.location = '/';
        let accessBy = localStorage.getItem('accessBy')
        console.log('go home: accessBy(',accessBy,')')
        if(accessBy === 'query') {
            // window.parent.location = localStorage.getItem('windowParentLocation');
            window.location.href='/?query='+localStorage.getItem('query')
        } else {
            window.location.href='/'
        }
    }

    const closeModal = () => {
        localStorage.setItem('scanData','')
        localStorage.removeItem('qrcodeID')
        localStorage.removeItem('eventID')
        localStorage.removeItem('name')
        setConfirm(false)
        setModalErrorIsOpen(false)
        setModalErrorClickToCollectIsOpen(false)
        // history.push('/')
        // window.location = '/';
        let accessBy = localStorage.getItem('accessBy')
        console.log('go home: accessBy(',accessBy,')')
        if(accessBy === 'query') {
            // window.parent.location = localStorage.getItem('windowParentLocation');
            window.location.href='/?query='+localStorage.getItem('query')
        } else {
            window.location.href='/'
        }
    }

    const closeModalWaiting = () => {
        setWaitingClickToCollectPoint(false)
    }

    Modal.setAppElement('#root');
    
    return (
        <React.Fragment>
            {/* Check groupType for render home page */}
            {
                // for groupType = 'gt'
                groupType === 'gt' ?
                    <div className="home-gt">
                        <div className="container-top">
                            <h3>คูปองส่วนลด</h3>
                        </div>

                        {/* Status */}
                        <div className="main-status">
                            <div className="main-box box-style _flex">
                                <div className="_left">
                                    <p>จำนวนคูปองที่มี</p>
                                    <div className="main-text">
                                        <img src={IconTicket} alt="icon" />
                                        <h1 className="font-black">{ !!dataApi.total_coupons ? (dataApi.total_coupons).toLocaleString('en-US') : 0 }<span>&nbsp;ใบ</span></h1>
                                    </div>
                                </div>
                                <div className="_right">
                                    <p>ส่วนลดที่เหลือ</p>
                                    <div className="main-text">
                                        <img src={IconDiscount} alt="icon" />
                                        <h1 className="font-blue">{ !!dataApi.total_discount ? (dataApi.total_discount).toLocaleString('en-US') : 0}<span>&nbsp;บาท</span></h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            clickToCollectDataAmount > 0 ?
                                <div className="click-to-collect">
                                    <h3 className="headertext font-black">คูปองพิเศษ</h3>
                                    <div className="click-to-collect-box">
                                        <div className={`coupon-list-box ${clickToCollectDataAmount === 1?'one-coupon':''}`} id={dataApi.collect_coupons.length}>
                                            {
                                                dataApi.collect_coupons.map((item,i)=>{
                                                    return (
                                                        <CouponClickToCollectCard childRef={ref} key={i} dataItem={item} dataGroupType={groupType} idx={i} onClick={()=>{handleSelectClickToCollect(item)}} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            : null
                        }

                        {/* Tabs */}
                        <Tabs>
                            <TabList>
                                <Tab>คูปองส่วนลด</Tab>
                                <Tab>ใช้แล้ว / หมดอายุ</Tab>
                            </TabList>

                            <TabPanel className="react-tabs__tab-panel">
                                {
                                    dataApi.new_coupons.length !== 0 || !dataApi.new_coupons ?
                                        <div className="coupon-list-box" style={{position: 'relative', height: windowH - (!clickToCollectDataAmount ? 240 :380)+'px', paddingBottom: '60px', overflow: 'auto'}}>
                                            {
                                                dataApi.new_coupons.map((item,i)=> {
                                                    return (
                                                        <CouponRedCardView key={i} dataItem={item} dataGroupType={groupType} idx={i} />
                                                    )
                                                })
                                            }


                                            <div className="btn-row _bottom" style={{top: 'auto', bottom: 0}}>
                                                {
                                                    groupType === 'gt' ?
                                                        dataApi.new_coupons.length ? 
                                                            <React.Fragment>
                                                                <Button3D text="กดเพื่อใช้คูปอง" onClick={gotoSelectCoupon}/>
                                                            </React.Fragment>
                                                        :
                                                            <div style={{opacity: '0', pointerEvents: 'none', width: '100%'}}>
                                                                <Button3D text="กดเพื่อใช้คูปอง"/>
                                                            </div>
                                                    :   
                                                    groupType === 'ws' ?
                                                        // dataApi.new_coupons.length ? 
                                                        //     <React.Fragment>
                                                        //         <Button3D text="กดเพื่อรับคูปอง" onClick={gotoScanCoupon}/>
                                                        //     </React.Fragment>
                                                        // :
                                                        //     <div style={{opacity: '0', pointerEvents: 'none', width: '100%'}}>
                                                        //         <Button3D text="กดเพื่อรับคูปอง"/>
                                                        //     </div>

                                                        <React.Fragment>
                                                            <Button3D text="กดเพื่อรับคูปอง" onClick={gotoScanCoupon}/>
                                                        </React.Fragment>
                                                    :
                                                        dataApi.new_coupons.length ? 
                                                            <React.Fragment>
                                                                <Button3D text="กดเพื่อใช้คูปอง" onClick={gotoSelectCoupon}/>
                                                                <Button3D text="กดเพื่อรับคูปอง" onClick={gotoScanCoupon}/>
                                                            </React.Fragment>
                                                        :
                                                            <div style={{opacity: '0', pointerEvents: 'none', width: '100%'}}>
                                                                <Button3D text="กดเพื่อใช้คูปอง"/>
                                                                <Button3D text="กดเพื่อรับคูปอง"/>
                                                            </div>

                                                }
                                            </div>
                                        </div>
                                            
                                        :
                                        <div className="empty-coupon txt-center">
                                            <img src={IconEmptyCoupon} alt="icon" />
                                            <h3 className="font-black">คุณยังไม่มีคูปองส่วนลด</h3>
                                            <p>คูปองส่วนลดของคุณท่ี่สามารถแลกส่วนลดได้<br/>จะถูกเก็บไว้ที่นี่</p>


                                            <div className="btn-row _bottom" style={{top: 'auto', bottom: 0}}>
                                                {
                                                    groupType === 'ws' ?
                                                        // dataApi.new_coupons.length ? 
                                                        //     <React.Fragment>
                                                        //         <Button3D text="กดเพื่อรับคูปอง" onClick={gotoScanCoupon}/>
                                                        //     </React.Fragment>
                                                        // :
                                                        //     <div style={{opacity: '0', pointerEvents: 'none', width: '100%'}}>
                                                        //         <Button3D text="กดเพื่อรับคูปอง"/>
                                                        //     </div>

                                                        <React.Fragment>
                                                            <Button3D text="กดเพื่อรับคูปอง" onClick={gotoScanCoupon}/>
                                                        </React.Fragment>
                                                    : null

                                                }
                                            </div>
                                        </div>
                                        
                                }
                            </TabPanel>
                            <TabPanel>
                                {
                                    dataApi.old_coupons.length !== 0 || !dataApi.old_coupons ?
                                    <div className="coupon-list-box" style={{position: 'relative', height: windowH - (!clickToCollectDataAmount ? 240 :380)+'px', paddingBottom: '60px', overflow: 'auto'}}>
                                            {
                                                dataApi.old_coupons.map((item,i)=> {
                                                    return (
                                                        <CouponBlackCardView key={i} dataItem={item} dataGroupType={groupType} idx={i} />
                                                    )
                                                })
                                            }               
                                        </div>
                                    :
                                        <div className="empty-coupon txt-center">
                                            <img src={IconEmptyCoupon} alt="icon" />
                                            <h3 className="font-black">คุณยังไม่ได้ใช้คูปองส่วนลด</h3>
                                            <p>คูปองส่วนลดของคุณท่ี่สามารถแลกส่วนลดได้<br/>จะถูกเก็บไว้ที่เมนู คูปองส่วนลด</p>
                                        </div>
                                }
                                <div className="btn-row _bottom" style={{ bottom: '0'}}></div>
                            </TabPanel>
                        </Tabs>
                    </div>
                : (groupType === 'ws' && accessBy === 'id') ?
                    // <ScanCoupon />
                    gotoScanCouponViaLIFF()
                :   
                    <HomeWs /> // for groupType = 'ws'
            }
            <Modal 
                isOpen={confirmClickToCollect}
                className="_modal box-style modal-selectcoupon" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="modal-content txt-center">
                    <h3>ยินดีด้วย คุณได้เก็บ</h3>
                    <h3 style={{margin: '0', color: '#2b6fb6'}}>คูปอง {(clickToCollectData.value).toLocaleString('en-US')} บาท</h3>
                    <p>(วันหมดอายุ { !!(clickToCollectData.date) ? clickToCollectData.date : '-' })</p>
                    {/* <h3>คูปอง {!!(item.ecp_value) ? (item.ecp_value).toLocaleString('en-US') : 0} บาท</h3> */}
                    {/* <p>รหัส {item.ecp_id}</p> */}
                    <br/>
                    <div className="btn-row txt-center">
                        <ButtonRound color="blue" text="ปิด" onClick={()=>{closeModalConfirmClickToCollect()}} />
                    </div>
                </div>
            </Modal>

            <Modal 
                isOpen={confirmClickToCollectPoint}
                className="_modal box-style modal-selectcoupon" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="modal-content txt-center">
                    <h3>กดยืนยันเพื่อใช้ <span style={{color: '#ff0000', fontSize: '2.4rem'}}>{(clickToCollectDataPoint.point).toLocaleString('en-US')}</span> คะแนน แลก</h3>
                    <h3 style={{margin: '0', color: '#2b6fb6'}}>คูปองส่วนลด {(clickToCollectDataPoint.value).toLocaleString('en-US')} บาท</h3>
                    <p>(หมดอายุ { !!(clickToCollectDataPoint.date) ? clickToCollectDataPoint.date : '-' })</p>
                    {/* <h3>คูปอง {!!(item.ecp_value) ? (item.ecp_value).toLocaleString('en-US') : 0} บาท</h3> */}
                    {/* <p>รหัส {item.ecp_id}</p> */}
                    <br/>
                    <div className="btn-row txt-center">
                        <ButtonRound color="white" text="ยกเลิก" onClick={()=>{setConfirmClickToCollectPoint(false)}} />
                        <ButtonRound color="blue" text="ยืนยัน" onClick={()=>{handleConfirmClickToCollect(clickToCollectDataPoint);}} />
                    </div>
                </div>
            </Modal>

            <Modal 
                isOpen={waitingClickToCollectPoint}
                className="_modal box-style modal-selectcoupon" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="modal-content txt-center">
                    {/* <h3>ระบบกำลังดำเนินการตัดคะแนนเพื่อแลกคูปอง</h3> */}
                    <h3>ระบบกำลังดำเนินการ คุณจะได้รับคูปองเมื่อทำการตัดคะแนนเรียบร้อยแล้ว</h3>
                    <br/>
                    <div className="btn-row txt-center">
                        <ButtonRound color="blue" text="ปิด" onClick={()=>{closeModalWaiting()}} />
                    </div>
                </div>
            </Modal>

            <Modal 
                isOpen={successClickToCollectPoint}
                className="_modal box-style modal-selectcoupon" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="modal-content txt-center">
                    {/* <h3>{nameMsg}</h3> */}

                    <div dangerouslySetInnerHTML={{__html: nameMsg}}></div>
                    {/* <h3>ยินดีด้วย คุณได้เก็บ</h3>
                    <h3 style={{margin: '0', color: '#2b6fb6'}}>คูปอง {clickToCollectDataPoint.value} บาท</h3>
                    <p>(หมดอายุ { !!(clickToCollectDataPoint.date) ? clickToCollectDataPoint.date : '-' })</p> */}
                    {/* <h3>คูปอง {!!(item.ecp_value) ? (item.ecp_value).toLocaleString('en-US') : 0} บาท</h3> */}
                    {/* <p>รหัส {item.ecp_id}</p> */}
                    <br/>
                    <div className="btn-row txt-center">
                        <ButtonRound color="blue" text="ปิด" onClick={()=>{closeModal()}} />
                    </div>
                </div>
            </Modal>

            <Modal 
                isOpen={confirmRedeemView}
                className="_modal box-style modal-selectcoupon" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="modal-content txt-center">
                    <h3>คุณจะได้ส่วนลด { !!(redeemDataDetail.total_all_value) ? (redeemDataDetail.total_all_value).toLocaleString('en-US') : 0 } บาท<br/>จากกับพนักงาน โดยแลกกับคูปอง</h3>
                    {/* {qrCodeData} */}
                    <ul className="modal-coupon-list">
                        {
                            redeemDataList.map((item,i)=> {
                                return (
                                    <li key={i} style={{paddingBottom: '10px'}}>
                                        <h3>{i+1}. คูปอง {!!(item.ecp_value) ? (item.ecp_value).toLocaleString('en-US') : 0} บาท</h3>
                                        <p>รหัส {item.ecp_id}</p>
                                    </li>
                                )
                            })
                        }
                        
                    </ul>
                    <br/>
                    <div className="btn-row txt-center">
                        {/* <ButtonRound color="grey" text="ยกเลิก" onClick={()=>{handleActionRedeem("reject")}} /> */}
                        <ButtonRound color="blue" text="ยืนยัน" onClick={()=>{handleActionRedeem("accept")}} />
                    </div>

                </div>
            </Modal>

            <Modal 
                isOpen={summaryRedeemAction}
                className="_modal box-style modal-selectcoupon" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="modal-content txt-center">
                    <h3>คุณได้แลกคูปอง ดังต่อไปนี้</h3>{/* {qrCodeData} */}
                    <ul className="modal-coupon-list">
                        {
                            redeemActionDataList.map((item,i)=> {
                                return (
                                    <li key={i} style={{paddingBottom: '10px'}}>
                                        <h3>{i+1}. คูปอง {!!(item.ecp_value) ? (item.ecp_value).toLocaleString('en-US') : 0} บาท</h3>
                                        <p>รหัส {item.ecp_id}</p>
                                    </li>
                                )
                            })
                        }
                        
                    </ul>
                    <h3>เป็นส่วนลดมูลค่า { !!(redeemActionDataDetail.total_all_value) ? (redeemActionDataDetail.total_all_value).toLocaleString('en-US') : 0 } บาท<br/>ให้กับพนักงาน เรียบร้อยแล้ว</h3>
                    
                    <br/>
                    <div className="btn-row txt-center">
                        <ButtonRound color="blue" text="กลับหน้าหลัก" onClick={closeModal} />
                    </div>

                </div>
            </Modal>

            <Modal 
                isOpen={confirm}
                className="_modal box-style modal-selectcoupon" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="modal-content txt-center">
                    <h3>คุณได้แลกคูปอง ดังต่อไปนี้</h3>

                    <ul className="modal-coupon-list">
                        {
                            
                            viewCouponDataList.map((item,i)=> {
                                return (
                                    <li key={i} style={{paddingBottom: '10px'}}>
                                        <h3>{i+1}. คูปอง { !!item.face_value ? (item.face_value).toLocaleString('en-US') : 0} บาท</h3>
                                        <p>หมดอายุวันที่ {showEndDate(item.end_date)}</p>
                                    </li>
                                )
                            })
                        }
                        
                    </ul>

                    <p style={{color: '#000', marginTop: '6px'}}>เป็นส่วนลดมูลค่า { !!viewCouponData.total_face_value ? (viewCouponData.total_face_value).toLocaleString('en-US') : 0 } บาท<br/>กับคุณ { viewCouponData.ws_name } เรียบร้อยแล้ว</p>
                    <br/>
                    <div className="btn-row txt-center">
                        <ButtonRound color="blue" text="กลับหน้าหลัก" onClick={closeModalConfirm} />
                    </div>
                </div>
            </Modal>

            <Modal 
                isOpen={modalErrorIsOpen} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    <p>{errorMsg}</p>
                    {
                        errorMsg === 'คูปองนี้สิ้นสุดระยะเวลาในการเก็บแล้ว' ? null : <p className="font-black">กรุณาลองอีกครั้ง</p>
                    }
                    <br />
                    <br/>
                    <ButtonRound text="ตกลง" color="blue" onClick={closeModal}/>
                </div>
            </Modal>

            <Modal 
                isOpen={modalErrorClickToCollectIsOpen} 
                className="_modal box-style modal-errClickToCollect" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    {/* <p>{errorMsg}</p> */}
                    <div className="msg" dangerouslySetInnerHTML={{__html: errorMsg}}></div>
                    <br />
                    <ButtonRound text="ตกลง" color="blue" onClick={closeModal}/>
                </div>
            </Modal>

        </React.Fragment>
    )
}

export default Home
