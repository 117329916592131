import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import QRCode from "react-qr-code";
import Modal from 'react-modal';
import moment from 'moment';

// import { DataContext } from '../App';

import Button3D from '../components/Button3D';
import ButtonRound from '../components/ButtonRound';

function SelectCouponConfirm(props) {
    const history = useHistory()
    const selectedData = history.location.state.data
    // const { selectedData, setSelectedData } = useContext(DataContext)
    const [dataGenQrcode, setDataGenQrcode] = useState({
        "success": 0,
        "qrcode": "",
        "qr_refid": "",
        "ts_str": "",
        "qr_exp_date": "",
        "total_face_value": 0,
        "total_topup_value": 0,
        "total_ecp": 0
    })

    const [error, setError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [message, setMessage] = useState('');
    const [modalErr, setModalErr] = useState(false)
    const [windowH, setWindowH] = useState(window.innerHeight)

    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');

    const resizeWindow = () => {
        setWindowH(window.innerHeight)
    }

    useEffect(() => {
        setError('')
        console.log('gen_qrcode',selectedData)

        window.addEventListener("resize", resizeWindow);

        const getDataGenQrcode = async () => {
            const res = await fetch(`https://${apiLocation}/ecp/api/user/gen_qrcode`, {
                method: 'POST',
                body: JSON.stringify({
                    "ecp_id": selectedData
                }),
                headers: {
                    "Authorization": "Bearer "+accessToken,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });
    
            res
            .json()
            .then(res => {
                if (res.success === 0) {
                    setModalErr(true);
                    // setMessage(res.message);
                    if(Object.keys(res.message).length !== 0) {
                        console.error(res)
                        if(typeof res.message === 'object') {
                            setMessage('ไม่สามารถทำรายการได้')
                        } else {
                            setMessage(res.message)
                        }
                    } else {
                        setMessage('ไม่สามารถทำรายการได้')
                    }
                    // setModalErr(true);
                    // setIsLoaded(true);
    
                } else {
                    const data = res;
                    console.log(data)
                    setDataGenQrcode(data)
                    setIsLoaded(true)
                }
    
            })
            .catch(err => {
                console.log(err);
                setMessage('เกิดข้อผิดพลาด')
                setModalErr(true)
            });
    
        }
        
        getDataGenQrcode()

        return () => {
            resizeWindow()
        }
    }, [accessToken, apiLocation, selectedData])

    

    function showEndDate(endDateVal) {
        const monthThArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'];
        const end = endDateVal
        
        const endD = moment(end).format('DD');
        const endM = moment(end).format('M');
        const endY = moment(end).format('YYYY');

        const valueEnddate = endD+' '+monthThArr[endM - 1]+' '+(Number(endY)+543);
        
        return valueEnddate;
    } 

    function showEndTime(endTimeVal) {
        const end = endTimeVal

        const endTime = moment(end).format(' HH:mm')
        return endTime;
    }
    
    const handleCloseModal = () => {
        // setSelectedData([])
        setModalErr(false)
        // history.push('/')
        let accessBy = localStorage.getItem('accessBy')
        console.log('go home: accessBy(',accessBy,')')
        if(accessBy === 'query') {
            // window.parent.location = localStorage.getItem('windowParentLocation');
            window.location.href='/?query='+localStorage.getItem('query')
        } else {
            window.location.href='/'
        }
    }

    const gotoHome = () => {
        let accessBy = localStorage.getItem('accessBy')
        console.log('go home: accessBy(',accessBy,')')
        if(accessBy === 'query') {
            window.parent.location = localStorage.getItem('windowParentLocation');
            // window.location.href='/?query='+localStorage.getItem('query')
        } else {
            window.location.href='/'
        }
    }

    return (
        <React.Fragment>
            {
                !isLoaded ? 
                <div className="container loading">
                    <div className="container-top">
                    <h3>...Loading...</h3>
                    </div>
                </div>
                :
                <React.Fragment>
                    <div className="container-top">
                        {/* <h3>แลกส่วนลด</h3> */}
                    </div>

                    <div className="main-status main-genqr">
                        <div className="ticket-box box-style txt-center">
                            <div className="_header">
                                {/* <h3 className="font-black">แลกส่วนลด</h3> */}
                                {/* <p className="font-black">ส่ง QR นี้ให้ร้านค้าส่งสแกน หรือพิมพ์โค้ดด้านล่างเพื่อรับส่วนลดมูลค่า <span style={{fontSize: '2rem'}}>{!!dataGenQrcode.total_face_value ? dataGenQrcode.total_face_value.toLocaleString('en-US') : 0}</span> บาท</p> */}
                                <h3 className="font-black" style={{lineHeight: '1'}}>คูปองส่วนลด <span style={{fontSize: '2rem'}}>{!!dataGenQrcode.total_face_value ? dataGenQrcode.total_face_value.toLocaleString('en-US') : 0}</span> บาท {!!dataGenQrcode.total_minimum_purchase ? 'สำหรับ ซื้อบุหรี่เชสเตอร์อย่างน้อย '+dataGenQrcode.total_minimum_purchase.toLocaleString('en-US')+' แถว' : null}</h3>
                                <p className="font-black">ส่ง QR นี้ให้ร้านค้าส่งสแกน <br/>หรือ พิมพ์โค้ดด้านล่าง</p>
                            </div>
                            <div className="_content">
                                <div className="qrcode-box">
                                    <QRCode value={dataGenQrcode.qrcode} size={170} />
                                </div>
                                <h3 className="font-blue">
                                    {dataGenQrcode.qrcode}
                                </h3>
                                <b>ใช้ได้จนถึง&nbsp;&nbsp;&nbsp;&nbsp;{showEndTime(dataGenQrcode.qr_exp_date)} น. { showEndDate(dataGenQrcode.qr_exp_date) }</b>
                                <p className="main-notice font-blue">กรุณาบันทึกภาพ หรือ จดรหัส<br/>ด้านบนไว้ก่อนกลับสู่หน้าหลัก</p>

                                <div className="btn-row  _bottom _footer" style={{top: (windowH - 240)+'px', bottom: 'auto', position: 'static'}}>
                                    <Button3D text="กลับหน้าหลัก" onClick={gotoHome} />
                                </div>
                            </div>
                            <div className="ticket-footer"></div>
                        </div>
                    </div>
                </React.Fragment>
            }
            
            <Modal
                isOpen={modalErr}
                className="_modal box-style app"
                style={{
                overlay: {
                    backgroundColor: 'rgba(0,0,0,0.5)'
                }
                }
                }
            >
                <div className="_error-modal center">
                <p className="font-black">{message} {error}</p>
                {
                    message === 'คูปองนี้สิ้นสุดระยะเวลาในการเก็บแล้ว' || error === 'คูปองนี้สิ้นสุดระยะเวลาในการเก็บแล้ว' ? null : <p className="font-black">กรุณาลองอีกครั้ง</p>
                }
                <br />
                
                    <ButtonRound text="กลับหน้าหลัก" color="blue" onClick={handleCloseModal} />
                </div>
            </Modal>

        </React.Fragment>
    )
}

export default SelectCouponConfirm
